import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import useShows from '../../hooks/useShows';

import Layout from '../../components/Layout';
import Hero from '../../components/Hero';
import Shows from '../../components/Shows';

const ShowsPage = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					hero: {
						image: { childImageSharp: { fluid: hero } },
						alignment
					},
					upcomingLimit,
					emptyMessage,
					pastLimit
				},
				html: body
			}
		}
	} = data;

	const upcomingShows = useShows( { limit: upcomingLimit || null, upcoming: true } );
	const pastShows = useShows( { limit: pastLimit || null, past: true, sort: 'desc' } );

	return (
		<Layout>
			{	hero
				? <Hero alignment={ alignment } fluid={ hero } />
				: null
			}
			<section className="separated">
				<div className="wrapper">
					<div className="body" dangerouslySetInnerHTML={ { __html: body } } />
				</div>
			</section>
			<section className={ pastShows.length ? 'separated' : null }>
				<div className="wrapper">
					<h3 className="section-header">Upcoming Shows</h3>
					<Shows
						emptyMessage={ emptyMessage }
						shows={ upcomingShows }
					/>
				</div>
			</section>
			{ pastShows.length
				? <section>
					<div className="wrapper">
						<h3 className="section-header">Past Shows</h3>
						<Shows
							shows={ pastShows }
						/>
					</div>
				</section>
				: null
			}
		</Layout>
	);
};

ShowsPage.propTypes = { data: PropTypes.object.isRequired };

export default ShowsPage;

export const query = graphql`
query ShowsPage {
	file(relativePath: {eq: "pages/shows.md"}) {
		childMarkdownRemark {
			frontmatter {
				hero {
					image {
						childImageSharp {
							fluid(maxWidth: 3000, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
					alignment
				}
				upcomingLimit
				emptyMessage
				pastLimit
			}
			html
		}
	}
}
`;
